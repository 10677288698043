export const starColors = [
    "#F5A623",
    "#F39C12",
    "#F1C40F",
    "#F7DC6F",
    "#FDEBD0",
    "#F5B041",
    "#F8C471",
    "#F9E79F",
    "#F4D03F",
    "#F7DC6F",
    "#F2C94D",
    "#F08080",
    "#F28482",
    "#F1948A",
    "#F5A4A9",
    "#F8B1B3",
    "#F7C6C7",
    "#F9D9D9",
    "#F2E6E6",
    "#F2E8E8",
    "#F7CECE",
    "#f7f7f7",
    "#d9e6f2",
    "#d0e0f4",
    "#c4e6f4",
    "#c2e0f4",
    "#c1d9f2",
    "#c2c8e6",
    "#f7f7f7",
    "#f7f8fb",
];

export const stars = ["★", "✶", "✴", "✵", "✪", "❂", "⚜", "⚘", "⚚", "⚛", "⚙"];
