import { useState, useEffect } from "react";
import { starColors, stars } from "./StarConfig";
import "./Stars.css";

function Stars(props) {
    const [starData, setStarData] = useState([]);
    const [isMoving, setIsMoving] = useState(true);

    useEffect(() => {
        setStarData(generateStars(Math.floor(Math.random() * 30) + 20));
    }, []);

    useEffect(() => {
        let interval;
        if (isMoving) {
            interval = setInterval(() => {
                setStarData((stars) => {
                    return stars.map((star) => {
                        const speed = star.speed ?? Math.floor(Math.random() * 2) + 1;
                        const left = parseFloat(star.left) - speed / 20;
                        if (left < -10) {
                            // Wrap around when star goes off-screen
                            return { ...star, left: "110%", right: `${100 - left}%`, top: `${getRandomNumber()}%` };
                        } else {
                            return { ...star, left: `${left}%`, right: `${100 - left}%` };
                        }
                    });
                });
            }, 25);
        }

        return () => clearInterval(interval);
    }, [isMoving]);

    useEffect(() => {
        setIsMoving(props.isMoving);
    }, [props.isMoving]);

    return (
        <div className="stars">
            {props.children}
            {starData.map((star, index) => {
                const style = {
                    left: star.left,
                    right: star.right,
                    top: star.top,
                    color: star.color,
                };

                return (
                    <div key={index} style={style} className="star">
                        {star.character}
                    </div>
                );
            })}
        </div>
    );
}

export default Stars;

function getRandomNumber() {
    return Math.floor(Math.random() * 100) + 1;
}

function generateStars(n) {
    return Array.from({ length: n }, () => {
        const speed = Math.floor(Math.random() * 8) + 1; // Random speed between 1 and 8
        const left = `${getRandomNumber()}%`;
        const right = `${100 - parseFloat(left)}%`;
        const color = starColors[Math.floor(Math.random() * starColors.length)]; // Random color for each star
        return {
            character: stars[Math.floor(Math.random() * stars.length)],
            left,
            right,
            top: `${getRandomNumber()}%`,
            speed,
            color,
        };
    });
}
