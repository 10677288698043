import ReactDOM from 'react-dom';
import Main from './ui/Main';


try {
  ReactDOM.render(
    <Main />,
    document.getElementById('root')
  );
} catch (error) {
  console.error('Failed to render Main component:', error);
}
